import React from 'react';
import {motion as m} from "framer-motion";
// Import Styles
import './About.scss';

function About() {
  return (
    <>
      <m.div className='wrapper about'
      initial={{ clipPath:"inset(0px round 10px)", y: "100vh", zIndex: "1" }} 
      animate={{ clipPath:"inset(0px round 0px)", y: "0", zIndex: "0", transition:{delay: "0.25", duration: "0.5", ease: "easeIn"} }}
      exit={{ clipPath:"inset(10px round 10px)", filter: "brightness(0.9)", transition:{duration: "0.25", ease: "easeOut"} }}
      >
        <h1>About</h1>
        {/* About */}
        <div className='grid_container'>
          <div className="col" data-column="6" data-column-mq="12">
            <h2>Hi! I’m a designer based in Lisbon — working internationally to create digital solutions that are easy to use, accessible to all, and frustration-free</h2>
            <div className='space mobile' data-space="xsmall"></div>
          </div>
          <div className="col desktop" data-column="1"></div>
          <div className="col" data-column="4" data-column-mq="12">
            <p>Obsessed with all things graphic and visual from an early age, I pursued an education in arts and graduated in Design and Visual Communication in 2014.</p><br/>
            <p>Since then, I have been fortunate to work on diverse projects in multiple fields and, recently, decided to focus on the web3 space.</p><br/>
            <p>This rapidly-evolving field has allowed me to fulfill my passion of creating visually stunning and user-centered designs for exciting decentralised applications.</p>
            <p>At the moment I’m a proud member of the Protokol team where I work full-time as a UX/UI designer.</p>
            <div className='space' data-space="medium"></div>
            <img className="bcrvlh" src={require("../img/bcrvlh.jpg")} alt="Bruno Carvalho"/>
          </div>
        </div>
        <div className='space' data-space="xlarge"></div>
        <div className='divider'></div>
        <div className='space' data-space="small"></div>
        {/* Services */}
        <div className='grid_container'>
          <div className="col" data-column="6" data-column-mq="12">
            <h2>Services</h2>
          </div>
          <div className="col desktop" data-column="1"></div>
          <div className="col" data-column="4" data-column-mq="12">
            <p>My expertise lies in assisting brands to envision and experiment with their potential, crafting distinctive solutions, and introducing products and services to the market. Irrespective of the outcome, my aim is to provide fresh insights into conventional assumptions, approaches, and solutions.</p><br/>
            <div className='space' data-space="small"></div>
            <h4>Brand Identity</h4><br/>
            <h4>Brand Guidelines</h4><br/>
            <h4>Stationery</h4><br/>
            <h4>User Interface</h4><br/>
            <h4>User Experience</h4>
          </div>
        </div>
        <div className='space' data-space="xlarge"></div>
        <div className='divider'></div>
        <div className='space' data-space="small"></div>
        {/* Clients */}
        <div className='grid_container'>
          <div className="col" data-column="6" data-column-mq="12">
            <h2>Clients</h2>
          </div>
          <div className="col desktop" data-column="1"></div>
          <div className="col" data-column="4" data-column-mq="12">
            <p>Since 2015, I’ve worked with small and large, local or internationally, forward-thinking business that strive to innovate. Some of those were:</p><br/>
            <div className='space' data-space="small"></div>
            {/* <h4>Protokol</h4><br/> */}
            <h4>Knot Kids</h4><br/>
            <h4>Portable Molecule</h4><br/>
            <h4>Mérito Total</h4><br/>
            <h4>CruzaRitmos</h4><br/>
            <h4>Madre Pérola</h4><br/>
            <h4>Monte do Galeirão</h4><br/>
            <h4>RePaint</h4><br/>
            <h4>Nave Motas&Acessórios</h4><br/>
            <h4>Patamar</h4><br/>
            <h4>Saraiva+Associados</h4><br/>
            <h4>Jellycode</h4><br/>
            <h4>Legacy</h4><br/>
            <h4>Flor de Lima</h4>
          </div>
        </div>
        <div className='space' data-space="xlarge"></div>
        <div className='divider'></div>
        <div className='space' data-space="small"></div>
        {/* Get in touch */}
        <div className='grid_container'>
          <div className="col" data-column="6" data-column-mq="12">
            <h2>Get in touch</h2>
          </div>
          <div className="col desktop" data-column="1"></div>
          <div className="col" data-column="4" data-column-mq="12">
            <p>If you would like to discuss a project, or simply learn more about me over a coffee, feel free to get in touch ✨</p><br/>
            <div className='space' data-space="small"></div>
            <a href="mailto:geral@crvlh.com"><h4>Email</h4></a><br/>
            <a href="https://t.me/bcrvlh"><h4>Telegram</h4></a><br/>
            <a href="https://www.linkedin.com/in/brunorcrvlh/"><h4>LinkedIn</h4></a>
          </div>
        </div>  
        <div className='space' data-space="large"></div>     
      </m.div>
    </>
  )
}

export default About;