import {BrowserRouter as Router} from "react-router-dom";
import CustomCursor from 'custom-cursor-react';
import 'custom-cursor-react/dist/index.css';
// Import Components
import NavBar from './components/NavBar';
import AnimatedRoutes from './components/AnimatedRoutes';
// Import Style
import './App.scss';

const App = () => (    
    <Router>
      <CustomCursor
        targets={['a']}
        customClass='custom-cursor'
        dimensions={30}
        fill='var(--text)'
        opacity={1} 
        targetScale={2}
        smoothness={{
          movement: 0.2,
          scale: 0.2,
          opacity: 1,
        }}
      />
      <NavBar/>
      <AnimatedRoutes/>
    </Router>
);

export default App;