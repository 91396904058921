import React from 'react';

const Clock = props => {

    const [date, setDate] = React.useState(new Date());

    React.useEffect(() => {

        var timerID = setInterval( () => tick(), 1000 );
            return function cleanup() {clearInterval(timerID);};
        });

        function tick() {
            setDate(new Date());
        }

        return (<h4>{date.toLocaleTimeString()}</h4>);
}


export default Clock;