import React from 'react';
import {Routes, Route, useLocation} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
// Import Components
import Home from '../pages/Home';
import About from '../pages/About';
// import Work from '../pages/Work';
import UnderConstruction from '../pages/UnderConstruction';


function AnimatedRoutes() {
    const location = useLocation();
    return (
        <>
            <AnimatePresence>
                <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<Home/>}></Route>
                    <Route path="/about" element={<About/>}></Route>
                    <Route path="/work" element={<UnderConstruction/>}></Route>
                    <Route path="*" element={<Home to="/" replace />} />
                </Routes>
            </AnimatePresence>
        </>
    )
}

export default AnimatedRoutes
