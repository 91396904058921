import React from 'react';
import {useState} from "react";
import {motion as m} from "framer-motion";
// Import Styles
import './Gradient.scss';

//const GradientWidth = 500;
const SpeedX = 25;
const SpeedY = 25;

function Gradient() {
  const [x, setX] = useState(0);
  const [speedX, setSpeedX] = useState(SpeedX);
  const [y, setY] = useState(0);
  const [speedY, setSpeedY] = useState(SpeedY);
  return (
    <>
      <m.div
        animate={{ x: x, y: y }}
        transition={{ease: "linear"}}
        onAnimationComplete={() => {
          setX(x + speedX);
          setY(y + speedY);
          if (x <= window.innerWidth) {setSpeedX(SpeedX);}
          if (x >= window.innerWidth / 2) {setSpeedX(-SpeedX);}
          if (y >= window.innerHeight / 2) {setSpeedY(-SpeedY);}
          if (y <= -window.innerHeight / 2) {setSpeedY(SpeedY);}
        }}
        className='gradient'
      >
      </m.div>
    </>
  );
}

export default Gradient;