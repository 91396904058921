import { Link, useMatch, useResolvedPath } from "react-router-dom"
// Import Styles
import './NavBar.scss';

function NavBar() {
  return (
    <>
      <menu className='navbar'>
        <CustomLink to="/" className='nav-link'>Home</CustomLink>
        <CustomLink to="/about" className='nav-link'>About</CustomLink>
        <CustomLink to="/work" className='nav-link'>Work</CustomLink>
      </menu>
    </>
  )
}

export default NavBar;

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "selected" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}