import React from 'react';
import {motion as m} from "framer-motion";
// Import Styles
import './Work.scss';


function UnderConstruction() {
  return (
    <>
      <m.div className='wrapper work'
      initial={{ clipPath:"inset(0px round 10px)", y: "100vh", zIndex: "1" }} 
      animate={{ clipPath:"inset(0px round 0px)", y: "0", zIndex: "0", transition:{delay: "0.25", duration: "0.5", ease: "easeIn"} }}
      exit={{ clipPath:"inset(10px round 10px)", filter: "brightness(0.9)", transition:{duration: "0.25", ease: "easeOut"} }}
      >
        <h1>Work</h1>
        {/* Work */}
        <div className='grid_container'>
          <div className="col" data-column="6" data-column-mq="12">
            <h2>Selected Work</h2>
            <h2>Under Construction</h2>
            <div className='space mobile' data-space="xsmall"></div>
          </div>
          <div className="col desktop" data-column="1"></div>
          <div className="col" data-column="4" data-column-mq="12">
            <p>I’m currently working on adding exciting new projects! Please check back soon for updates, and feel free to contact me if you have any questions.</p>
            <div className='space' data-space="medium"></div>
            <img src={require("../img/underconstruction.gif")} alt="Under Construction"/>
          </div>
        </div>
      </m.div>
    </>
  )
}

export default UnderConstruction;