import React from 'react';
import {motion as m} from "framer-motion";
// Import Components
import Clock from '../components/Clock';
import Gradient from '../components/Gradient';
// Import Styles
import './Home.scss';

const line1 = "Bruno"
const line2 = "Carvalho"

const sentence = {
  hidden: { opacity: 1 },
  visible: { opacity: 1, transition: { delayChildren: 0.75, staggerChildren:"0.1" }},
}
const letter = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
}


function Home() {
  const currDate = new Date().toLocaleDateString();

  return (
    <>
      <m.div className='wrapper home'
      initial={{ clipPath:"inset(0px round 10px)", y: "100vh", zIndex: "1" }} 
      animate={{ clipPath:"inset(0px round 0px)", y: "0", zIndex: "0", transition: { delay: "0.25", duration: "0.5", ease: "easeIn" }}}
      exit={{ clipPath:"inset(10px round 10px)", filter: "brightness(0.9)", transition: { duration: "0.25", ease: "easeOut" }}}
      >
        <h1>Homepage</h1>
        {/* Info */}
        <div className='grid_container'>
          <div className="col" data-column="3" data-column-mq="6">
          <h4>UX/UI Designer</h4>
          </div>
          <div className="col" data-column="3" data-column-mq="6">
            <h4>Lisbon, PT</h4>
          </div>
          <div className="col" data-column="3" data-column-mq="6">
            <Clock/>
          </div>
          <div className="col" data-column="3" data-column-mq="6">
            <h4>{currDate}</h4>
          </div>
        </div>
        {/* Logo */}
        <div className='logo'>
          <m.h2
          variants={sentence} 
          initial="hidden"
          animate="visible"
          exit="exit">
            {line1.split("").map( (char, index) => {
              return (<m.span key={char + "_" + index} variants={letter}>{char}</m.span>)
            })}
            <br/>
            {line2.split("").map( (char, index) => {
              return (<m.span key={char + "_" + index} variants={letter}>{char}</m.span>)
            })}
          </m.h2>
        </div>
        <Gradient/>
      </m.div>
    </>
  )
}

export default Home;